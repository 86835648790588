import React from "react";
import EdgewareImage from "./images/example-edgeware.svg";
import GolfGuideImage from "./images/example-golf-guide.svg";
import GolfBoxImage from "./images/example-golf-box.svg";
import MandrakeImage from "./images/example-mandrake.svg";
import NonaImage from "./images/example-nona.svg";
import PurplePepperImage from "./images/example-purple-pepper.svg";
import WiriImage from "./images/example-wiri.svg";
import InfinitecImage from "./images/example-infinitec.svg";
import LogofolioImage from "./images/example-logofolio.svg";
import UnitedcapitalImage from "./images/example-unitedcapital.svg";
import TrinityImage from "./images/example-trinity.svg";
import HortelanosImage from "./images/example-hortelanos.svg";

import designerOtmani from "./images/designers/designer-ali-otmani.png";
import designerCucuk from "./images/designers/designer-aleksandar-cucukovic.png";
import designerMcCarthy from "./images/designers/designer-karl-mccarthy.png";
import designerJohansson from "./images/designers/designer-anthon-johansson.png";
import designerTanic from "./images/designers/designer-bozidar-tanic.png";
import designerAndrei from "./images/designers/designer-andrei.png";
import designerAdam from "./images/designers/designer-adam.jpg";
import designerAngela from "./images/designers/designer-angela.png";
import designerZalo from "./images/designers/designer-zalo.jpg";
import designerGrimes from "./images/designers/designer-jordan-grimes.png";
import designerKyle from "./images/designers/designer-kyle.jpg";


export const ExamplesData = [
  {
    id: "edgeware",
    title: "Edgeware Brand Bible",
    imgUrl: EdgewareImage,
    color: "#151515"
  },
  {
    id: "purple-pepper",
    title: "Purple Pepper Brand Guide",
    imgUrl: PurplePepperImage,
    color: "#621E82"
  },
  {
    id: "mandrake",
    title: "The Mandrake Logomark Proposal",
    imgUrl: MandrakeImage,
    color: "#ffffff"
  },
  {
    id: "golf-guide",
    title: "The Golf Guide Branding",
    imgUrl: GolfGuideImage,
    color: "#ffffff"
  },
  {
    id: "nona",
    title: "Nona Logo Presentation",
    imgUrl: NonaImage,
    color: "#ffd800"
  },
  {
    id: "golf-box",
    title: "Golf Box Branding Guidelines",
    imgUrl: GolfBoxImage,
    color: "#DB067B"
  },
  {
    id: "infinitec",
    title: "Infinitec Brand Page",
    imgUrl: InfinitecImage,
    color: "#D63C2B"
  },
  {
    id: "logofolio",
    title: "Logofolio Page",
    imgUrl: LogofolioImage,
    color: "#000"
  },
  {
    id: "wiri",
    title: "Wiri Brand Identity Guide",
    imgUrl: WiriImage,
    color: "#5C7EFF"
  },
  {
    id: "unitedcapital",
    title: "United Capital Brand Page",
    imgUrl: UnitedcapitalImage,
    color: "#FFFFFF"
  },
  {
    id: "trinity",
    title: "Trinity Brand Identity Presentation",
    imgUrl: TrinityImage,
    color: "#202028"
  },
  {
    id: "los-hortelanos",
    title: "Los Hortelanos Brand Presentation",
    imgUrl: HortelanosImage,
    color: "#467227"
  },
]

export const DesignersHero = [
  { path: designerZalo, href: "https://beta.bravemark.co/zalo0" },
  { path: designerOtmani, href: "https://beta.bravemark.co/itsalielo" },
  { path: designerCucuk, href: "https://beta.bravemark.co/alexondesign" },
  { path: designerMcCarthy, href: "https://beta.bravemark.co/karlmccarthydesign" },
  { path: designerJohansson, href: "https://beta.bravemark.co/anthonjohansson" },
  { path: designerTanic, href: "https://beta.bravemark.co/btanic" },
];

export const SenjaTestimonialsId = null // "4192376a-f4ec-4dd1-854e-c7dd3f083f8b"

export const customTestimonials = [
  {
    image: designerAngela,
    link: "https://beta.bravemark.co/loveandlogic",
    name: "Angela Roche",
    job: "Creative Director",
    text: <p>Just added my first brand to Bravemark! I've been looking for the right brand management/guidelines platform for ages and my search is over! Loving Bravemark ❤️</p>,
    date: "Dec 2, 2023",
  },
  {
    image: designerZalo,
    link: "https://beta.bravemark.co/zalo0",
    name: "Zalo Estévez",
    job: "Logo & Visual Identity Designer",
    text: <p>Incredible user experience! Super intuitive and aesthetic. The perfect tool to deliver brand guidelines in an easy and very comfortable way to the customer. 100% Recommended!</p>,
    date: "Sep 19, 2023",
  },
   {
    image: designerAdam,
    link: "https://beta.bravemark.co/oakfold",
    name: "Adam Greasley",
    job: "Brand and type designer",
    text: <p>As a brand designer, being able to hand over an online brandpage to a client is super useful. Bravemark makes this process extremely quick and easy.</p>,
    date: "Dec 4, 2023",
  },
  {
    image: designerAndrei,
    link: "https://beta.bravemark.co/artangent",
    name: "Andrei Traista",
    job: "Brand Identity Designer",
    text: <p>Glad I stumbled upon Bravemark on Twitter, never seen something similar. Even though the platform is just taking it's first stepts, it has huge potential and could become a really cool tool for brand identity designers</p>,
    date: "Apr 14, 2023",
  },
  {
    image: designerJohansson,
    link: "https://beta.bravemark.co/anthonjohansson",
    name: "Anthon Johansson",
    job: "Graphic Designer",
    text: <p>The team behind Bravemark genuinely care about their product and I believe that has helped them to create a really easy-to-use service. Whilst Bravemark is still in its early stages, the future is very promising and the speed a presentation can be completed is staggering compared to all the competition.</p>,
    date: "May 8, 2023",
  },
  {
    image: designerMcCarthy,
    link: "https://beta.bravemark.co/karlmccarthydesign",
    name: "Karl McCarthy",
    job: "Brand Identity Designer",
    text: <p>Really easy interface to navigate. I found myself quickly snapping modules together, getting into a quick rhythm building presentations. Overall I'm very impressed!</p>,
    date: "Dec 16, 2022",
  },
  {
    image: designerTanic,
    link: "https://beta.bravemark.co/btanic",
    name: "Bozidar Tanic",
    job: "Brand Identity Designer",
    text: <p>I've been using Bravemark for a week now and I'm happy with it. It's a great alternative to the best well-known platforms out there and it's perfect for what I need. It's hassle-free and easy to use, which is exactly what I was looking for.</p>,
    date: "Dec 11, 2022",
  },
  {
    image: designerKyle,
    link: "https://beta.bravemark.co/kcourtright",
    name: "Kyle Courtright",
    job: "Brand Identity Designer",
    text: <p>Bravemark is changing the way identity designers present their work and I'm loving it. Modern, intuitive and robust. No doubt will continue to be a disrupter in the creative space. Well done!</p>,
    date: "Apr 14, 2023",
  },
  {
    image: designerGrimes,
    link: "https://beta.bravemark.co/j7grimes",
    name: "Jordan Grimes",
    job: "Brand Identity Designer",
    text: <p>Getting the chance to utilize Bravemark (beta) was a great experience. I was used to creating brand guidelines in InDesign manually so it was a breath of fresh air to essentially drag and drop a guidelines page.</p>,
    date: "Dec 11, 2022",
  },
]