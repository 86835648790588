// extracted by mini-css-extract-plugin
export var container = "Testimonials-module--container--KT70C";
export var embed = "Testimonials-module--embed--N7A+-";
export var testimonials = "Testimonials-module--testimonials--tXPhq";
export var testimonialContainer = "Testimonials-module--testimonialContainer--gi0pB";
export var testimonial = "Testimonials-module--testimonial--vRWlO";
export var header = "Testimonials-module--header--P7u0U";
export var image = "Testimonials-module--image--FZ3ul";
export var name = "Testimonials-module--name--a8iDR";
export var job = "Testimonials-module--job--ZMZML";
export var stars = "Testimonials-module--stars--xRjPY";
export var text = "Testimonials-module--text--UqoIh";
export var date = "Testimonials-module--date---nxck";
export var action = "Testimonials-module--action--IDt8t";