import React from "react";
import * as moduleStyles from "./Hero.module.scss";
import { Image } from "../image/Image";
import brandImage from "./images/brand-presentation-ui.png";
import { Button } from "../form/Button";
import { DesignersHero } from "../../fixtures";

export default function Hero(props) {
  // const [registered, setRegistered] = useState(false);
  // function handleSuccess() {
  //   setRegistered(true);
  // }

  // let elements = (
  //   <div className={moduleStyles.col}>
  //     <h3>We are currently in closed Beta. Join the waitlist to get early access & special offers.</h3>
  //     <div className={moduleStyles.form}>
  //       <Form label="Join the Waitlist" onSuccess={handleSuccess} />
  //     </div>
  //     <p className="footnote">By signing up you are also accepting to receive our newsletter and marketing emails.</p>
  //   </div>
  // )
  // if (registered) {
  //   elements = (
  //     <div className={moduleStyles.col}>
  //       <ThankYou size="large" />
  //     </div>
  //   )
  // }

  return (
    <div className={moduleStyles.container}>
      <div className={moduleStyles.inner}>
        <p className="text-lead grey-300 mt-none">Provide your clients with a better way to access their brand assets.</p> 
        <h1>Design Awesome Interactive Online Brand Guidelines</h1>
        <div className={moduleStyles.subtitle}>
          <p className="text-lead grey-100">Create responsive online brand identity guidelines with downloadable assets without writing any code in 10 minutes or less.</p>
          <p className="text-small orange-500">Join 200+ Brand Identity Designers including</p>
          <Designers designers={DesignersHero} />
        </div>
      </div>
      {/* TODO mobile image */}
      <img className={moduleStyles.brandImage}
        src={brandImage} alt="Logo presentation"
        width={1184}
      />
    </div>
  )
}

function Designers(props) {
  const elements = props.designers.map(({path, href}) => {
    const name = path.match(/designer-(.*)/)[1].replace("-", " ");

    return (
      <Button key={path} link to={href}>
        <Image rounded
          src={path}
          alt={name}
          title={name}
          width={48}
          height={48}
        />
      </Button>
    )
  });

  return <div className={moduleStyles.designers}>{elements}</div>
}