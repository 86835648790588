import React from "react";
import * as moduleStyles from "./Double.module.scss";

export default function Double(props) {

  const innerClasses = [
    moduleStyles.inner,
    props.reverseOrder === true ? moduleStyles.reverse : null,
  ]

  const imgColClasses = [
    moduleStyles.col,
    moduleStyles.image,
  ]

  const textColClasses = [
    moduleStyles.col,
    moduleStyles.text,
  ]
  
  return (
    <div className={moduleStyles.container}>
      <div className={innerClasses.join(" ")}>
        <div className={imgColClasses.join(" ")}>
          <img src={props.imageUrl} alt={props.imageAlt} />
        </div>
        <div className={textColClasses.join(" ")}>
          <h2>{props.title}</h2>
          {props.children}
        </div>
      </div>
    </div>
  )
}

Double.defaultProps = {
  title: "Title",
  reverseOrder: false,
  imageAlt: ""
}