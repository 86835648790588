import React from "react";
import { fillColor, PathIcon } from "./Icon";

// ri-star-smile-fill
export default function StarIcon(props) {
  return (
    <PathIcon {...props}>
      <path d="M11.9986 0.5L16.2246 6.68342L23.4113 8.7918L18.8364 14.7217L19.052 22.2082L11.9986 19.6897L4.94519 22.2082L5.16082 14.7217L0.585938 8.7918L7.77262 6.68342L11.9986 0.5ZM9.99862 12H7.99862C7.99862 14.2091 9.78948 16 11.9986 16C14.1408 16 15.8897 14.316 15.9937 12.1996L15.9986 12H13.9986C13.9986 13.1046 13.1032 14 11.9986 14C10.9443 14 10.0805 13.1841 10.0041 12.1493L9.99862 12Z" fill={fillColor(props)}></path>
    </PathIcon>
  );
}

StarIcon.defaultProps = {
  colorDisabled: "var(--yellow-500)",
}

