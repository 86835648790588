import React from "react";
import {OutboundLink} from "gatsby-plugin-google-gtag-optin";
import * as moduleStyles from "./ExampleGrid.module.scss";

export default function ExampleGrid({data}) {

  let items = data.map((item) => {
    return <Item key={item.id} id={item.id} title={item.title} imgUrl={item.imgUrl} color={item.color} />
  })

  return (
    <div className={moduleStyles.container}>
      <div className={moduleStyles.inner}>
        {items}
      </div>
    </div>
  )
}

function Item({id, title, imgUrl, color}) {
  let styles = {
    backgroundColor: color
  }
  let url = `/examples/${id}`;
  return (
    <div className={moduleStyles.item}>
      <div className={moduleStyles.itemInner}>
        <OutboundLink href={url} title={title} target="_blank" rel="noreferrer">
        <div className={moduleStyles.visual} style={styles}>
            <img src={imgUrl} alt={title} />
        </div>
        </OutboundLink>
        <p className="text-small font-bold"><OutboundLink href={url} title={title} target="_blank" rel="noreferrer">{title}</OutboundLink></p>
      </div>
    </div>
  )
}