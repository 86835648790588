import React from "react"
import Hero from "../components/hero/Hero";
import Double from "../components/double/Double";
import Spacer from "../components/spacer/Spacer";
import Centered from "../components/centered/Centered";
import ExampleGrid from "../components/example-grid/ExampleGrid";
import BrandingPresentationImage from "../images/index/responsive-brand-presenations@2x.png";
import AssetsImage from "../images/index/interactive-brand-presentations@2x.png";
import AssetsModulesImage from "../images/index/branding-assets-in-one-place.png";
import SharingLogosImage from "../images/index/share-brandpage-url@2x.png";
import ShowcaseImage from "../images/index/showcase-brand-identity-design-work@2x.png";
import BuilderImage from "../images/index/branding-drag-drop-builder.png";
import {ExamplesData, SenjaTestimonialsId} from "../fixtures";
import { Testimonials } from "../components/testimonial/Testimonials";
import presentation1 from "../images/index/brand-presentation-builder.svg";
import presentation2 from "../images/index/brand-asset-handoff.svg";
import presentation3 from "../images/index/transfer-brand-assets.svg";
import { Button } from "../components/form/Button";
import Page from "../components/page/Page";
import "../styles/index.scss";


// markup
const IndexPage = () => {
  return (
    <Page>
      <Spacer amount="90" hideOnNarrowDevices={true} />

      <Hero />

      <Spacer amount="100" />

      <Centered>
        <h2 className="max-width-spacing-screen-big">Spend more time focusing on your design work, not designing online brand guidelines</h2>

        <Spacer amount="90"/>
        <div className="design3">
          <div>
            <img src={presentation1} alt="Download brand assets" width={300} height={214} />
            <p>Easily create custom stylish online brand identity presentations that are responsive and host downloadable brand assets.</p>
          </div>
          <div>
            <img src={presentation2} alt="Brand Identity Handoff" width={300} height={214} />
            <p>Provide your clients with a practical handoff solution where they only need to download the asset they need in the format they need.</p>
          </div>
          <div>
            <img src={presentation3} alt="Share Brand Guidelines" width={300} height={214} />
            <p>Forget about ever having to send clunky Zip files and simply share a URL link with your clients and make brand assets easily available to anyone.</p>
          </div>
        </div>
      </Centered>

      <Reviews />
      
      <Features />

      <Examples />

      <Spacer amount="50" />

      <Centered className="max-width-spacing-screen-normal">
        <p className="title2 mt-spacing-l">🦁</p>
        <h2>Join the tribe</h2>
        <p>
          When you join Bravemark, you also become part of our tribe. That means you will be a part of our community and that it’s important for us to hear what you have to say so that we can learn how we can build features that are important to you!</p>
        <JoinWaitlistButton className="mt-spacing-m" />
      </Centered>

      <Spacer />
    </Page>
  )
}

function JoinWaitlistButton(props) {
  return <Button to="/join-beta" large label="Join the Beta" width="285px" {...props} />
}

function Reviews() {
  return (
    <>
      <a id="reviews"></a>
      <Spacer amount="170"/>
      <Centered>
        <Testimonials senjaDataId={SenjaTestimonialsId}>
          <div className="max-width-spacing-screen-small">
            <p className="mb-spacing-l">Want to see more?<br/>Read all our customer reviews on our wall of love.</p>
            <Button large label="Read all the reviews" to="https://love.bravemark.co/" width="360px" />
          </div>
        </Testimonials>
      </Centered>
    </>
  )
}

function Features() {
  const spacer = <Spacer amount="320" className="featuresSpacer" />

  return (
    <>
      <a id="features"></a>
      <Spacer amount="260" />
      <Centered className="max-width-spacing-screen-big">
        <h2>Step up your game and impress your clients with our modern identity design handoff solution</h2>
        <p className="mt-spacing-xl max-width-spacing-screen-normal">Bringing practicality to the brand identity design workflow. Read all about the features and benefits Bravemark provides to brand identity designers and their clients.</p>
      </Centered>
      <Spacer amount="140" />
      
      <Double title="Responsive Brand Guidelines, at last" imageUrl={BrandingPresentationImage} imageAlt="Brand guidelines on different devices">
        <p>Say goodbye to boring PDF brand guidelines and create responsive online brand guidelines that are perfectly optimized for any device.</p>
      </Double>

      {spacer}

      <Double title="Make your client’s life easier with interactive brand guidelines" imageUrl={AssetsImage} imageAlt="Brand identity guideline modules" reverseOrder={true}>
        <p>Select individual brand assets and only download the necessary files. Add notes to individual assets with valuable information. Copy color #hex values easily and make your brand guideline an actionable asset, and not just something to look through.</p>
      </Double>

      {spacer}

      <Double title="Customizable No-Code Presentation Builder" imageUrl={BuilderImage} imageAlt="Brand guideline builder">
        <p>Easily design your custom online branding presentation or brand guidelines with our intuitive drag-and-drop builder. No need to have any coding knowledge; just use our easy UI to customize it to your liking.</p>
      </Double>

      {spacer}

      <Double title="Access all your brand assets from a single page" imageUrl={AssetsModulesImage} imageAlt="Example of assets modules" reverseOrder={true}>
        <p>Easily upload and organize brand assets, like logos and images, by just dropping them in the presentation sections, and make it easy for your clients to select what they want to download. Assets can be downloaded individually per file format or in one global zip file.</p>
      </Double>

      {spacer}

      <Double title="Hand off your work by simply sharing a URL" imageUrl={SharingLogosImage} imageAlt="Share brand guideline URL">
        <p>Once your #brandpage is published, send your clients a public or private URL. If any updates need to be made, simply make the changes and update it without the hassle of exporting PDFs, or sending emails.</p>
      </Double>

      {spacer}

      <Double title="Showcase your brand identity work and get discovered by others" imageUrl={ShowcaseImage} imageAlt="Showcasing your brand identity design work" reverseOrder={true}>
        <p>Expand your professional circle by exhibiting your brand identity design projects and gain recognition from fellow designers. Keep up with the latest design trends and follow esteemed designers.</p>
      </Double>
    </>
  )
}

function Examples() {
  return (
    <>
      <a id="examples"></a>
      <Spacer amount="220" />
      <Centered>
        <h2>What other designers are making</h2>
        <p className="max-width-spacing-screen-small">Discover public brand identity projects other designers have published to their portfolios on Bravemark.</p>
      </Centered>
      
      <Spacer amount="40" />

      <ExampleGrid data={ExamplesData} />

      <Spacer amount="50" />

      <Centered className="max-width-spacing-screen-small">
        <p>Find inspiration for your next project on Bravemark.</p>
        <Button large label="Discover public #brandpages" to="https://beta.bravemark.co/discover" width="320px" />
      </Centered>
    </>
  )
}

export default IndexPage
