import React, {useEffect, useRef, useState} from "react";
import Helmet from "react-helmet";
import { customTestimonials } from "../../fixtures";
import useResize from "../../hooks/useResize";
import { Button } from "../form/Button";
import StarIcon from "../icons/Star";
import { Image } from "../image/Image";
import * as moduleStyles from "./Testimonials.module.scss";


export function Testimonials(props) {
  const {
    senjaDataId
  } = props;

  const containerRef = useRef()
  const {width} = useResize(containerRef)

  return (
    <div className={moduleStyles.container} ref={containerRef}>
      <div className="max-width-spacing-screen-normal">
        <h2>See why designers love Bravemark</h2>
        <p>Over 200 brand identity designers are currently using Bravemark to provide their clients with responsive online brand guidelines.</p>
      </div>
      <div className={moduleStyles.testimonials}>
        { senjaDataId ? <SenjaTestimonials senjaDataId={senjaDataId} /> : <CustomTestimonials testimonials={customTestimonials} width={width} /> }
      </div>

      <div className={moduleStyles.action}>
        {props.children}
      </div>
    </div>
  )
}

function SenjaTestimonials({senjaDataId}) {
  return (
    <>
      <div className={moduleStyles.embed + " senja-frame-embed"} data-id={senjaDataId}></div>
      <Helmet>
        <script async type="text/javascript" src="https://widget.senja.io/embed/frame.js"></script>
      </Helmet>
    </>
  )
}

function CustomTestimonials({testimonials, width}) {
  const [shownTestimonials, setShownTestimonials] = useState(testimonials)

  useEffect(() => {
    let shown = testimonials

    if (width < 1030) {
      shown = testimonials.slice(0, 6)
    }

    setShownTestimonials(shown)
  }, [width, testimonials])

  return shownTestimonials.map((t) => <Testimonial key={t.name} {...t} />)
}

function Testimonial(props) {
  const {
    image,
    link,
    name,
    job,
    text,
    date,
    stars = 5,
  } = props

  const classes = [
    moduleStyles.testimonialContainer,
    moduleStyles.button,
  ]

  const starsElem = [...Array(stars)].map((u, i) => <StarIcon key={i} />)

  return (
    <Button className={classes.join(" ")} link to={link}>
      <div className={moduleStyles.testimonial}>
        <div className={moduleStyles.header}>
          <div className={moduleStyles.image}>
            <Image rounded
              src={image}
              alt={name}
              title={name}
              width={40}
              height={40}
            />
          </div>
          <div className={moduleStyles.nameAndJob}>
            <div className={moduleStyles.name}>{name}</div>
            <div className={moduleStyles.job}>{job}</div>
          </div>
        </div>
        <div className={moduleStyles.stars}>{starsElem}</div>
        <div className={moduleStyles.text}>{text}</div>
        <div className={moduleStyles.date}>{date}</div>
      </div>
    </Button>
  )
}